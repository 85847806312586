// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from '@reach/router';

// const JobEdit = () => {

//   const [job, setJob] = useState({
//     id:'',
//     image: '', 
//     title: '', 
//     description: '', 
//     salary: '',
//     category: '',
//     job_type: '',
//     location: '',
//     skills: '',
//   });



//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setJob({ ...job, [name]: value });
//   };


//   return (
//     <form >
//       <div>
//         <label>Image:</label>
//         <input type="text" name="image" value={job.image} onChange={handleInputChange} />
//       </div>
//       <div>
//         <label>Title:</label>
//         <input type="text" name="title" value={job.title} onChange={handleInputChange} />
//       </div>
//       <div>
//         <label>Description:</label>
//         <textarea name="description" value={job.description} onChange={handleInputChange}></textarea>
//       </div>
//       <div>
//         <label>Salary:</label>
//         <input type="text" name="salary" value={job.salary} onChange={handleInputChange} />
//       </div>
//       <div>
//         <label>Category:</label>
//         <input type="text" name="category" value={job.category} onChange={handleInputChange} />
//       </div>
//       <div>
//         <label>Job Type:</label>
//         <input type="text" name="job_type" value={job.job_type} onChange={handleInputChange} />
//       </div>
//       <div>
//         <label>Location:</label>
//         <input type="text" name="location" value={job.location} onChange={handleInputChange} />
//       </div>
//       <div>
//         <label>Skills:</label>
//         <input type="text" name="skills" value={job.skills} onChange={handleInputChange} />
//       </div>
//       <button type="submit">Update Job</button>
//     </form>
//   );
// };

// export default JobEdit;


// import React, { useContext, useEffect, useRef, useState } from "react";
// import { Link, navigate } from "gatsby";
// import PageWrapper from "../components/PageWrapper";
// import Select from 'react-select';
// import axios from "axios";
// import axiosConfig from '../axiosConfig';
// import network from "../../constants/Network";
// import GlobalContext from "../context/GlobalContext";
// import { textAlign } from "styled-system";

// const JobEdit = ({ location }) => {

//   const params = new URLSearchParams(location.search);
//   const jobId = params.get("id");
//   const [job, setJob] = useState([]);
//   const isMountedRef = useRef(false);
//   const gContext = useContext(GlobalContext);
//   const [isFocused, setIsFocused] = useState('');
//   const { user } = useContext(GlobalContext);

//   const [errors, setErrors] = useState({});
//   const [successMessage, setSuccessMessage] = useState('');

//   const imageInputRef = useRef(null);
//   const [imageUrl, setImageUrl] = useState('');
//   const [jobCategories, setJobCategories] = useState([]);
//   const [jobTypes, setJobTypes] = useState([]);
//   const [qualifications, setQualifications] = useState([]);
//   const [locations, setLocations] = useState([]);
//   const [states, setStates] = useState([]);
//   const [allSkills, setAllSkills] = useState([]);
//   const [institutions, setInstitutions] = useState([]);

//   const [experienceYear, setExperienceYear] = useState(0);
//   const [experienceMonth, setExperienceMonth] = useState(0);

//   const [formData, setFormData] = useState({
//     job_id: '',
//     image: '',
//     title: '',
//     description: '',
//     job_category: '',
//     qualification: '',
//     job_type: '',
//     location: '',
//     skills: [],
//     experience: '',
//     experience_in_months: '',
//     featured_job: 0,
//     institution: '',
//     state: '',
//     created_by: '',
//   });

//   const handleFetchedJobData = (data) => {

//     setFormData(prevFormData => ({
//       ...prevFormData,
//       job_id: data?.id || '',
//       image: data?.image || '',
//       title: data?.title || '',
//       description: data?.description || '',
//       job_category: data.job_category?.id || '',
//       qualification: data.qualification?.id || '',
//       job_type: data.job_type?.id || '',
//       location: data.location?.id || '',
//       skills: data?.skills.map(skill => skill?.id || null),
//       experience: data?.experience || '',
//       experience_in_months: data?.experience_in_months || '',
//       featured_job: data?.featured_job || 0,
//       institution: data.institution?.id || '',
//       state: data.state?.id || '',
//     }));

//     setImageUrl(data && data.image && `${network.serverip}/images/${data.image}`);

//     setExperienceYear(Math.floor(data?.experience_in_months / 12) || 0);
//     setExperienceMonth(data?.experience_in_months % 12 || 0);
//   }


//   useEffect(() => {
//     isMountedRef.current = true;

//     return () => {
//       isMountedRef.current = false;
//     };
//   }, []);

//   useEffect(() => {
//     if (user && user.id) {
//         setFormData((prevFormData) => ({
//             ...prevFormData,
//             created_by: user.id,
//         }));
//     }
// }, [user]);

//   useEffect(() => {
//     const fetchData = async () => {
//       await fetchJobCategories();
//       await fetchJobTypes();
//       await fetchQualifications();
//       await fetchLocations();
//       await fetchStates();
//       await fetchInstitutions();
//       await fetchSkills();
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {

//     if (!jobId) {
//       console.log('Waiting for the job id');
//       return;
//     }

//     const fetchJobDetails = async () => {
//       try {
//         const response = await axiosConfig.post(`/jobdetails-forweb/${jobId}`, {});
//         if (response.data.success && isMountedRef.current) {
//           setJob(response.data.data);
//           handleFetchedJobData(response.data.data);
//           console.log('This is the fetched job details:', response.data.data);
//         } else if (!response.data.success) {
//           if (isMountedRef.current) {
//             setJob([]);
//           }
//           console.error('Failed to fetch job details');
//         }
//       } catch (error) {
//         if (isMountedRef.current) {
//           setJob([]);
//         }
//         console.error('Error fetching job details:', error);
//       }
//     };

//     fetchJobDetails()
//   }, [jobId]);

//   const fetchJobCategories = async () => {
//     try {
//       const response = await axios.get('/jobcategories-list-web', {
//         baseURL: `${network.serverip}/api`,
//         headers: {
//           'Content-Type': 'application/json',
//         }
//       });

//       if (response.data.success && isMountedRef.current) {
//         const jobCategoryData = (response.data.data || []);
//         setJobCategories(jobCategoryData);
//         console.log('Fetched job categories:', jobCategoryData);
//       } else if (!response.data.success) {
//         console.error('Failed to fetch job categories');
//       }

//     } catch (error) {
//       console.error('Error fetching job categories:', error);
//     }
//   };

//   const fetchJobTypes = async () => {
//     try {
//       const response = await axios.get('/jobtypes-list-web', {
//         baseURL: `${network.serverip}/api`,
//         headers: {
//           'Content-Type': 'application/json',
//         }
//       });

//       if (response.data.success && isMountedRef.current) {
//         const jobTypeData = (response.data.data || []);
//         setJobTypes(jobTypeData);
//         console.log('Fetched job types:', jobTypeData);
//       } else if (!response.data.success) {
//         console.error('Failed to fetch job types');
//       }

//     } catch (error) {
//       console.error('Error fetching job types:', error);
//     }
//   };

//   const fetchQualifications = async () => {
//     try {
//       const response = await axios.get('/qualifications-list-web', {
//         baseURL: `${network.serverip}/api`,
//         headers: {
//           'Content-Type': 'application/json',
//         }
//       });

//       if (response.data.success && isMountedRef.current) {
//         const qualificationData = (response.data.data || []);
//         setQualifications(qualificationData);
//         console.log('Fetched qualifications:', qualificationData);
//       } else if (!response.data.success) {
//         console.error('Failed to fetch qualifications');
//       }

//     } catch (error) {
//       console.error('Error fetching qualifications:', error);
//     }
//   };

//   const fetchLocations = async () => {
//     try {
//       const response = await axios.get('/locations-list-web', {
//         baseURL: `${network.serverip}/api`,
//         headers: {
//           'Content-Type': 'application/json',
//         }
//       });

//       if (response.data.success && isMountedRef.current) {
//         const locationData = (response.data.data || []);
//         setLocations(locationData);
//         console.log('Fetched locations:', locationData);
//       } else if (!response.data.success) {
//         console.error('Failed to fetch locations');
//       }

//     } catch (error) {
//       console.error('Error fetching locations:', error);
//     }
//   };

//   const fetchStates = async () => {
//     try {
//       const response = await axios.get('/states-list-web', {
//         baseURL: `${network.serverip}/api`,
//         headers: {
//           'Content-Type': 'application/json',
//         }
//       });

//       if (response.data.success && isMountedRef.current) {
//         const stateData = (response.data.data || []);
//         setStates(stateData);
//         console.log('Fetched states:', stateData);
//       } else if (!response.data.success) {
//         console.error('Failed to fetch states');
//       }

//     } catch (error) {
//       console.error('Error fetching states:', error);
//     }
//   };

//   const fetchSkills = async () => {
//     try {
//       const response = await axios.get('/skills-list-web', {
//         baseURL: `${network.serverip}/api`,
//         headers: {
//           'Content-Type': 'application/json',
//         }
//       });

//       if (response.data.success && isMountedRef.current) {
//         const skillsData = (response.data.data || []);
//         setAllSkills(skillsData);
//         console.log('Fetched skills:', skillsData);
//       } else if (!response.data.success) {
//         console.error('Failed to fetch skills');
//       }

//     } catch (error) {
//       console.error('Error fetching skills:', error);
//     }
//   };

//   const fetchInstitutions = async () => {
//     try {
//       const response = await axios.get('/institutions-list-web', {
//         baseURL: `${network.serverip}/api`,
//         headers: {
//           'Content-Type': 'application/json',
//         }
//       });

//       if (response.data.success && isMountedRef.current) {
//         const institutionData = (response.data.data || []);
//         setInstitutions(institutionData);
//         console.log('Fetched states:', institutionData);
//       } else if (!response.data.success) {
//         console.error('Failed to fetch institutions');
//       }

//     } catch (error) {
//       console.error('Error fetching institutions:', error);
//     }
//   };

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.id]: e.target.value,
//     });
//   };

//   const handleSkillsChange = (selectedOptions) => {
//     const selectedSkillsIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
//     setFormData(prevState => ({
//       ...prevState,
//       skills: selectedSkillsIds
//     }));
//   };

//   const handleExperienceChange = (year, month) => {
//     const totalExperienceInMonths = year * 12 + month;
//     setFormData(prevState => ({
//       ...prevState,
//       experience_in_months: totalExperienceInMonths
//     }));

//     console.log('total exp:', year * 12 + month);

//   };

//   const onChangeYear = (e) => {
//     const value = parseInt(e.target.value) || 0;
//     setExperienceYear(value);
//     handleExperienceChange(value, experienceMonth);
//   };

//   const onChangeMonth = (e) => {
//     const value = parseInt(e.target.value) || 0;
//     setExperienceMonth(value);
//     handleExperienceChange(experienceYear, value);
//   };

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     setFormData({ ...formData, image: file });
//     setImageUrl(URL.createObjectURL(file));
//   };

//   const handleSuccessMessage = (message) => {
//     setSuccessMessage(message);

//     setTimeout(() => {
//       setSuccessMessage('');
//     }, 5000);
//   };

//   // const handleClear = () => {
//   //   setFormData(prevFormData => ({
//   //     ...prevFormData,
//   //     image: '',
//   //     title: '',
//   //     description: '',
//   //     job_category: '',
//   //     qualification: '',
//   //     job_type: '',
//   //     location: '',
//   //     skills: [],
//   //     experience: '',
//   //     experience_in_months: '',
//   //     featured_job: false,
//   //     institution: '',
//   //     state: '',
//   //   }));

//   //   setExperienceMonth(0);
//   //   setExperienceYear(0);

//   //   setImageUrl('');

//   //   if (imageInputRef.current) {
//   //     imageInputRef.current.value = '';
//   //   }

//   // };

//   const handleGoBack = () => {
//     if (window.history.length > 1) {
//       navigate(-1);
//     } else {
//       navigate('/dashboard-jobs');
//     }
//   }

//   const handleImageDelete = () => {
//     setImageUrl('');

//     if (imageInputRef.current) {
//       imageInputRef.current.value = '';
//     }

//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       image: '',
//     }));

//   }

//   const handleSubmit = async (e) => {

//     e.preventDefault();

//     console.log('Handle submit pressed');

//     setErrors({});

//     let isValid = true;

//     if (!formData.job_id) {
//       setErrors(prevErrors => ({
//         ...prevErrors,
//         editJobError: 'Job not found'
//       }));
//       isValid = false;

//       return;
//     }

//     if (!formData.created_by) {
//       setErrors(prevErrors => ({
//         ...prevErrors,
//         editJobError: 'Please sign in to continue'
//       }));
//       isValid = false;
//       // gContext.toggleSignInModal();

//       return;
//     }

//     if (!formData.title) {
//       setErrors(prevErrors => ({
//         ...prevErrors,
//         title: 'Please enter a job title'
//       }));
//       isValid = false;
//     }

//     if (!formData.description) {
//       setErrors(prevErrors => ({
//         ...prevErrors,
//         description: 'Please enter job description'
//       }));
//       isValid = false;
//     }

//     if (!formData.job_category) {
//       setErrors(prevErrors => ({
//         ...prevErrors,
//         job_category: 'Please select a job category'
//       }));
//       isValid = false;
//     }

//     if (!formData.qualification) {
//       setErrors(prevErrors => ({
//         ...prevErrors,
//         qualification: 'Please select a qualification'
//       }));
//       isValid = false;
//     }

//     if (!formData.job_type) {
//       setErrors(prevErrors => ({
//         ...prevErrors,
//         job_type: 'Please select a job type'
//       }));
//       isValid = false;
//     }

//     if (!formData.location) {
//       setErrors(prevErrors => ({
//         ...prevErrors,
//         location: 'Please select a job location'
//       }));
//       isValid = false;
//     }

//     if (!formData.institution) {
//       setErrors(prevErrors => ({
//         ...prevErrors,
//         institution: 'Please select an institution'
//       }));
//       isValid = false;
//     }

//     if (!formData.state) {
//       setErrors(prevErrors => ({
//         ...prevErrors,
//         state: 'Please select a state'
//       }));
//       isValid = false;
//     }

//     if (!isValid) {
//       return
//     }

//     try {

//       // Make the POST request with formData
//       const response = await axios.post(`${network.serverip}/api/edit-job-web`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         }
//       });

//       if (response.data.success && isMountedRef.current) {
//         console.log('Job updated successfully');

//         // Scroll to the top of the screen
//         // window.scrollTo({
//         //   top: 0,
//         //   behavior: 'smooth'
//         // });

//         // handleSuccessMessage(response.data.message);
//         // handleClear();

//         handleGoBack()

//       } else {
//         console.error('Failed to update job');
//       }

//     } catch (error) {
//       console.error('Error updating job:', error);

//       if (error.response) {

//         if (error.response.data.error) {
//           setErrors((prevErrors) => ({
//             ...prevErrors,
//             ...error.response.data.error,
//           }));
//         }

//         if (error.response.data.message) {
//           setErrors((prevErrors) => ({
//             ...prevErrors,
//             editJobError: error.response.data.message,
//           }));
//         }

//       } else {
//         setErrors(prevErrors => ({
//           ...prevErrors,
//           editJobError: 'Something went wrong, please try again',
//         }));
//       }
//     }
//   };



//   const styles = JobEditStyling();

//   return (
//     <>
//       <PageWrapper
//         headerConfig={{
//           button: "profile",
//           isFluid: true,
//           bgClass: "bg-default",
//           reveal: false,
//         }}
//       >
//         <div className="bg-default-1 pt-26 pt-lg-28 pb-13 pb-lg-25">
//           <div className="container">
//             <div className="row">
//               <div className="col-12">
//                 <div className="text-center">
//                   <p className="font-size-4 text-success min-height-40">
//                     {successMessage || ''}
//                   </p>
//                 </div>
//                 <form onSubmit={handleSubmit}>

//                   <div className="form-group">
//                     <label
//                       htmlFor="image"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Image:
//                     </label>
//                     <input
//                       type="file"
//                       className="form-control"
//                       name="image"
//                       onChange={handleImageChange}
//                       id="image"
//                       ref={imageInputRef}
//                       onFocus={() => setIsFocused('image')}
//                       onBlur={() => setIsFocused('')}
//                       style={isFocused === 'image' ? styles.focussdedInput : {}}
//                     />
//                     {errors?.image ? <p className="font-size-3 text-danger">
//                       {errors.image}
//                     </p> : null}
//                     {imageUrl && (
//                       <div className="d-flex justify-content-start mt-7">
//                         <div style={styles.imageContainer}>
//                           <img
//                             src={imageUrl}
//                             alt=""
//                             className="img-fluid"
//                             style={styles.imageStyle}
//                           />
//                           <button
//                             type="button"
//                             onClick={handleImageDelete}
//                             style={styles.imageDeleteButton}
//                           >
//                             <i className="fas fa-times"></i>
//                           </button>
//                         </div>
//                       </div>
//                     )}
//                   </div>

//                   <div className="form-group">
//                     <label
//                       htmlFor="title"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Title:
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Job title"
//                       id="title"
//                       name="title"
//                       value={formData.title}
//                       onChange={handleChange}
//                       onFocus={() => setIsFocused('title')}
//                       onBlur={() => setIsFocused('')}
//                       style={isFocused === 'title' ? styles.focussdedInput : {}}
//                     />
//                     {errors?.title ? <p className="font-size-3 text-danger">
//                       {errors.title}
//                     </p> : null}
//                   </div>

//                   <div className="form-group">
//                     <label
//                       htmlFor="description"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Description:
//                     </label>
//                     <textarea
//                       className="form-control"
//                       placeholder="Job description"
//                       id="description"
//                       name="description"
//                       value={formData.description}
//                       onChange={handleChange}
//                       rows="4"
//                       onFocus={() => setIsFocused('description')}
//                       onBlur={() => setIsFocused('')}
//                       style={isFocused === 'description' ? styles.focussdedInput : {}}
//                     />
//                     {errors?.description ? <p className="font-size-3 text-danger">
//                       {errors.description}
//                     </p> : null}
//                   </div>

//                   <div className="form-group">
//                     <label
//                       htmlFor="job_category"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Select job category:
//                     </label>
//                     <select
//                       id="job_category"
//                       name="job_category"
//                       className="form-control"
//                       value={formData.job_category}
//                       onChange={handleChange}
//                       onFocus={() => setIsFocused('job_category')}
//                       onBlur={() => setIsFocused('')}
//                       style={isFocused === 'job_category' ? styles.focussdedInput : {}}
//                     >
//                       <option value="">Select a job category</option>
//                       {jobCategories.map(jobCategory => (
//                         <option key={jobCategory.id} value={jobCategory.id}>
//                           {jobCategory.title}
//                         </option>
//                       ))}
//                     </select>
//                     {errors?.job_category ? <p className="font-size-3 text-danger">
//                       {errors.job_category}
//                     </p> : null}
//                   </div>

//                   <div className="form-group">
//                     <label
//                       htmlFor="job_type"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Select job type:
//                     </label>
//                     <select
//                       id="job_type"
//                       name="job_type"
//                       className="form-control"
//                       value={formData.job_type}
//                       onChange={handleChange}
//                       onFocus={() => setIsFocused('job_type')}
//                       onBlur={() => setIsFocused('')}
//                       style={isFocused === 'job_type' ? styles.focussdedInput : {}}
//                     >
//                       <option value="">Select a job type</option>
//                       {jobTypes.map(jType => (
//                         <option key={jType.id} value={jType.id}>
//                           {jType.title}
//                         </option>
//                       ))}
//                     </select>
//                     {errors?.job_type ? <p className="font-size-3 text-danger">
//                       {errors.job_type}
//                     </p> : null}
//                   </div>

//                   <div className="form-group">
//                     <label
//                       htmlFor="qualification"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Select qualification:
//                     </label>
//                     <select
//                       id="qualification"
//                       name="qualification"
//                       className="form-control"
//                       value={formData.qualification}
//                       onChange={handleChange}
//                       onFocus={() => setIsFocused('qualification')}
//                       onBlur={() => setIsFocused('')}
//                       style={isFocused === 'qualification' ? styles.focussdedInput : {}}
//                     >
//                       <option value="">Select a qualification</option>
//                       {qualifications.map(qual => (
//                         <option key={qual.id} value={qual.id}>
//                           {qual.title}
//                         </option>
//                       ))}
//                     </select>
//                     {errors?.qualification ? <p className="font-size-3 text-danger">
//                       {errors.qualification}
//                     </p> : null}
//                   </div>

//                   <div className="form-group">
//                     <label
//                       htmlFor="location"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Select location:
//                     </label>
//                     <select
//                       id="location"
//                       name="location"
//                       className="form-control"
//                       value={formData.location}
//                       onChange={handleChange}
//                       onFocus={() => setIsFocused('location')}
//                       onBlur={() => setIsFocused('')}
//                       style={isFocused === 'location' ? styles.focussdedInput : {}}
//                     >
//                       <option value="">Select a location</option>
//                       {locations.map(loc => (
//                         <option key={loc.id} value={loc.id}>
//                           {loc.title}
//                         </option>
//                       ))}
//                     </select>
//                     {errors?.location ? <p className="font-size-3 text-danger">
//                       {errors.location}
//                     </p> : null}
//                   </div>

//                   <div className="form-group">
//                     <label
//                       htmlFor="state"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Select state:
//                     </label>
//                     <select
//                       id="state"
//                       name="state"
//                       className="form-control"
//                       value={formData.state}
//                       onChange={handleChange}
//                       onFocus={() => setIsFocused('state')}
//                       onBlur={() => setIsFocused('')}
//                       style={isFocused === 'state' ? styles.focussdedInput : {}}
//                     >
//                       <option value="">Select a state</option>
//                       {states.map(sta => (
//                         <option key={sta.id} value={sta.id}>
//                           {sta.title}
//                         </option>
//                       ))}
//                     </select>
//                     {errors?.state ? <p className="font-size-3 text-danger">
//                       {errors.state}
//                     </p> : null}
//                   </div>

//                   <div className="form-group">
//                     <label
//                       htmlFor="skills"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Skills:
//                     </label>
//                     <Select
//                       isMulti
//                       name="skills"
//                       options={allSkills.map(skill => ({ value: skill.id, label: skill.title }))} // Map allSkills to the required format
//                       className="basic-multi-select"
//                       classNamePrefix="select"
//                       onChange={handleSkillsChange}
//                       value={allSkills
//                         .filter(skill => formData.skills.includes(skill.id)) // Filter to find selected skills based on formData.skills
//                         .map(skill => ({ value: skill.id, label: skill.title }))} // Map to the required format for MultiSelect
//                       placeholder="Select skills"
//                       onFocus={() => setIsFocused('skills')}
//                       onBlur={() => setIsFocused('')}
//                       style={isFocused === 'skills' ? styles.focussdedInput : {}}
//                     />
//                     {errors?.skills ? <p className="font-size-3 text-danger">
//                       {errors.skills}
//                     </p> : null}
//                   </div>

//                   <div className="form-group">
//                     <label
//                       htmlFor="experience"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Experience:
//                     </label>
//                     <textarea
//                       className="form-control"
//                       placeholder="Experience"
//                       id="experience"
//                       name="experience"
//                       value={formData.experience}
//                       onChange={handleChange}
//                       rows="2"
//                       onFocus={() => setIsFocused('experience')}
//                       onBlur={() => setIsFocused('')}
//                       style={isFocused === 'experience' ? styles.focussdedInput : {}}
//                     />
//                     {errors?.experience ? <p className="font-size-3 text-danger">
//                       {errors.experience}
//                     </p> : null}
//                   </div>

//                   <div className="form-group">
//                     <label
//                       htmlFor="experience_in_months"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Experience (Years & Months):
//                     </label>
//                     <div className="d-flex">
//                       <select
//                         className="form-control mr-1"
//                         name="experienceYear"
//                         value={experienceYear}
//                         onChange={onChangeYear}
//                         id="experienceYear"
//                         onFocus={() => setIsFocused('experienceYear')}
//                         onBlur={() => setIsFocused('')}
//                         style={isFocused === 'experienceYear' ? styles.focussdedInput : {}}
//                       >
//                         <option value="">Select Years</option>
//                         {Array.from({ length: 31 }, (_, i) => (
//                           <option key={i} value={i}>{i} Year(s)</option>
//                         ))}
//                       </select>
//                       <select
//                         className="form-control ml-1"
//                         name="experienceMonth"
//                         value={experienceMonth}
//                         onChange={onChangeMonth}
//                         id="experienceMonth"
//                         onFocus={() => setIsFocused('experienceMonth')}
//                         onBlur={() => setIsFocused('')}
//                         style={isFocused === 'experienceMonth' ? styles.focussdedInput : {}}
//                       >
//                         <option value="">Select Months</option>
//                         {Array.from({ length: 12 }, (_, i) => (
//                           <option key={i} value={i}>{i} Month(s)</option>
//                         ))}
//                       </select>
//                     </div>
//                     {errors?.experience_in_months ? <p className="font-size-3 text-danger">
//                       {errors.experience_in_months}
//                     </p> : null}
//                   </div>

//                   <div className="form-group">
//                     <label
//                       htmlFor="institution"
//                       className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
//                     >
//                       Select hospital:
//                     </label>
//                     <select
//                       id="institution"
//                       name="institution"
//                       className="form-control"
//                       value={formData.institution}
//                       onChange={handleChange}
//                       onFocus={() => setIsFocused('institution')}
//                       onBlur={() => setIsFocused('')}
//                       style={isFocused === 'institution' ? styles.focussdedInput : {}}
//                     >
//                       <option value="">Select a hospital</option>
//                       {institutions.map(inst => (
//                         <option key={inst.id} value={inst.id}>
//                           {inst.title}
//                         </option>
//                       ))}
//                     </select>
//                     {errors?.institution ? <p className="font-size-3 text-danger">
//                       {errors.institution}
//                     </p> : null}
//                   </div>

//                   <div className="form-group">
//                     <div className="form-check-inline">
//                       <label
//                         htmlFor="featured_job"
//                         className="col-form-label font-size-4 text-black-2 font-weight-semibold line-height-reset mr-5"
//                       >
//                         Set as featured job:
//                       </label>
//                       <input
//                         type="checkbox"
//                         id="featured_job"
//                         name="featured_job"
//                         checked={formData.featured_job === 1}
//                         onChange={(e) =>
//                           setFormData({
//                             ...formData,
//                             featured_job: e.target.checked ? 1 : 0,
//                           })
//                         }
//                       />
//                     </div>
//                     {errors?.featured_job ? (
//                       <p className="font-size-3 text-danger">{errors.featured_job}</p>
//                     ) : null}
//                   </div>

//                   <div className="form-group d-flex justify-content-end mt-3">
//                     <button type="button"
//                       onClick={handleGoBack}
//                       className="btn btn-outline-primary mr-2">
//                       Cancel
//                     </button>
//                     <button type="submit"
//                       className="btn btn-primary">
//                       Edit job
//                     </button>
//                   </div>

//                   <div className="text-center">
//                     {errors?.editJobError ? (
//                       <p className="font-size-3 text-danger">{errors.editJobError}</p>
//                     ) : null}
//                   </div>

//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </PageWrapper>
//     </>
//   );
// };
// export default JobEdit;

// function JobEditStyling() {
//   const styles = {

//     imageContainer: {
//       position: 'relative',
//       display: 'inline-block'
//     },

//     imageStyle: {
//       maxHeight: '200px',
//       width: 'auto'
//     },

//     imageDeleteButton: {
//       position: 'absolute',
//       top: '-10px',
//       right: '-10px',
//       background: 'white',
//       color: 'gray',
//       borderRadius: '50%',
//       border: 'none',
//       width: '20px',
//       height: '20px',
//       cursor: 'pointer',
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//     },

//     focussdedInput: {
//       borderColor: '#3498db',
//       color: '#333',
//       outline: 'none',
//       borderWidth: '2px',
//       boxShadow: '0 0 5px rgba(52, 152, 219, 0.5)',
//     }

//   };

//   return styles;
// }

// The below code is for job-edit page with SidebarDashboard component

import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import Select from 'react-select';
import axios from "axios";
import axiosConfig from '../axiosConfig';
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import { textAlign } from "styled-system";
// import SidebarDashboard from "../components/SidebarDashboard";

const DashboardJobEdit = ({ location }) => {

  const params = new URLSearchParams(location.search);
  const jobId = params.get("id");
  const [job, setJob] = useState([]);
  const isMountedRef = useRef(false);
  const gContext = useContext(GlobalContext);
  const [isFocused, setIsFocused] = useState('');
  const { user } = useContext(GlobalContext);

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const imageInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState('');
  const [jobCategories, setJobCategories] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [locations, setLocations] = useState([]);
  const [states, setStates] = useState([]);
  const [allSkills, setAllSkills] = useState([]);
  const [institutions, setInstitutions] = useState([]);

  const [experienceYear, setExperienceYear] = useState(0);
  const [experienceMonth, setExperienceMonth] = useState(0);

  const [formData, setFormData] = useState({
    job_id: '',
    image: '',
    title: '',
    description: '',
    job_category: '',
    qualification: '',
    job_type: '',
    location: '',
    skills: [],
    experience: '',
    experience_in_months: '',
    featured_job: 0,
    institution: '',
    state: '',
    created_by: '',
  });

  const handleFetchedJobData = (data) => {

    setFormData(prevFormData => ({
      ...prevFormData,
      job_id: data?.id || '',
      image: data?.image || '',
      title: data?.title || '',
      description: data?.description || '',
      job_category: data.job_category?.id || '',
      qualification: data.qualification?.id || '',
      job_type: data.job_type?.id || '',
      location: data.location?.id || '',
      skills: data?.skills.map(skill => skill?.id || null),
      experience: data?.experience || '',
      experience_in_months: data?.experience_in_months || '',
      featured_job: data?.featured_job || 0,
      institution: data.institution?.id || '',
      state: data.state?.id || '',
    }));

    setImageUrl(data && data.image && `${network.serverip}/images/${data.image}`);

    setExperienceYear(Math.floor(data?.experience_in_months / 12) || 0);
    setExperienceMonth(data?.experience_in_months % 12 || 0);
  }


  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (user && user.id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        created_by: user.id,
      }));
    }
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchJobCategories();
      await fetchJobTypes();
      await fetchQualifications();
      await fetchLocations();
      await fetchStates();
      await fetchInstitutions();
      await fetchSkills();
    };

    fetchData();
  }, []);

  useEffect(() => {

    if (!jobId) {
      console.log('Waiting for the job id');
      return;
    }

    const fetchJobDetails = async () => {
      try {
        const response = await axiosConfig.post(`/jobdetails-forweb/${jobId}`, {});
        if (response.data.success && isMountedRef.current) {
          setJob(response.data.data);
          handleFetchedJobData(response.data.data);
          console.log('This is the fetched job details:', response.data.data);
        } else if (!response.data.success) {
          if (isMountedRef.current) {
            setJob([]);
          }
          console.error('Failed to fetch job details');
        }
      } catch (error) {
        if (isMountedRef.current) {
          setJob([]);
        }
        console.error('Error fetching job details:', error);
      }
    };

    fetchJobDetails()
  }, [jobId]);

  const fetchJobCategories = async () => {
    try {
      const response = await axios.get('/jobcategories-list-web', {
        baseURL: `${network.serverip}/api`,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success && isMountedRef.current) {
        const jobCategoryData = (response.data.data || []);
        setJobCategories(jobCategoryData);
        console.log('Fetched job categories:', jobCategoryData);
      } else if (!response.data.success) {
        console.error('Failed to fetch job categories');
      }

    } catch (error) {
      console.error('Error fetching job categories:', error);
    }
  };

  const fetchJobTypes = async () => {
    try {
      const response = await axios.get('/jobtypes-list-web', {
        baseURL: `${network.serverip}/api`,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success && isMountedRef.current) {
        const jobTypeData = (response.data.data || []);
        setJobTypes(jobTypeData);
        console.log('Fetched job types:', jobTypeData);
      } else if (!response.data.success) {
        console.error('Failed to fetch job types');
      }

    } catch (error) {
      console.error('Error fetching job types:', error);
    }
  };

  const fetchQualifications = async () => {
    try {
      const response = await axios.get('/qualifications-list-web', {
        baseURL: `${network.serverip}/api`,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success && isMountedRef.current) {
        const qualificationData = (response.data.data || []);
        setQualifications(qualificationData);
        console.log('Fetched qualifications:', qualificationData);
      } else if (!response.data.success) {
        console.error('Failed to fetch qualifications');
      }

    } catch (error) {
      console.error('Error fetching qualifications:', error);
    }
  };

  const fetchLocations = async () => {
    try {
      const response = await axios.get('/locations-list-web', {
        baseURL: `${network.serverip}/api`,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success && isMountedRef.current) {
        const locationData = (response.data.data || []);
        setLocations(locationData);
        console.log('Fetched locations:', locationData);
      } else if (!response.data.success) {
        console.error('Failed to fetch locations');
      }

    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const fetchStates = async () => {
    try {
      const response = await axios.get('/states-list-web', {
        baseURL: `${network.serverip}/api`,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success && isMountedRef.current) {
        const stateData = (response.data.data || []);
        setStates(stateData);
        console.log('Fetched states:', stateData);
      } else if (!response.data.success) {
        console.error('Failed to fetch states');
      }

    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get('/skills-list-web', {
        baseURL: `${network.serverip}/api`,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success && isMountedRef.current) {
        const skillsData = (response.data.data || []);
        setAllSkills(skillsData);
        console.log('Fetched skills:', skillsData);
      } else if (!response.data.success) {
        console.error('Failed to fetch skills');
      }

    } catch (error) {
      console.error('Error fetching skills:', error);
    }
  };

  const fetchInstitutions = async () => {
    try {
      const response = await axios.get('/institutions-list-web', {
        baseURL: `${network.serverip}/api`,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success && isMountedRef.current) {
        const institutionData = (response.data.data || []);
        setInstitutions(institutionData);
        console.log('Fetched states:', institutionData);
      } else if (!response.data.success) {
        console.error('Failed to fetch institutions');
      }

    } catch (error) {
      console.error('Error fetching institutions:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSkillsChange = (selectedOptions) => {
    const selectedSkillsIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setFormData(prevState => ({
      ...prevState,
      skills: selectedSkillsIds
    }));
  };

  const handleExperienceChange = (year, month) => {
    const totalExperienceInMonths = year * 12 + month;
    setFormData(prevState => ({
      ...prevState,
      experience_in_months: totalExperienceInMonths
    }));

    console.log('total exp:', year * 12 + month);

  };

  const onChangeYear = (e) => {
    const value = parseInt(e.target.value) || 0;
    setExperienceYear(value);
    handleExperienceChange(value, experienceMonth);
  };

  const onChangeMonth = (e) => {
    const value = parseInt(e.target.value) || 0;
    setExperienceMonth(value);
    handleExperienceChange(experienceYear, value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
    setImageUrl(URL.createObjectURL(file));
  };

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);

    setTimeout(() => {
      setSuccessMessage('');
    }, 5000);
  };

  // const handleClear = () => {
  //   setFormData(prevFormData => ({
  //     ...prevFormData,
  //     image: '',
  //     title: '',
  //     description: '',
  //     job_category: '',
  //     qualification: '',
  //     job_type: '',
  //     location: '',
  //     skills: [],
  //     experience: '',
  //     experience_in_months: '',
  //     featured_job: false,
  //     institution: '',
  //     state: '',
  //   }));

  //   setExperienceMonth(0);
  //   setExperienceYear(0);

  //   setImageUrl('');

  //   if (imageInputRef.current) {
  //     imageInputRef.current.value = '';
  //   }

  // };

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/dashboard-jobs');
    }
  }

  const handleImageDelete = () => {
    setImageUrl('');

    if (imageInputRef.current) {
      imageInputRef.current.value = '';
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      image: '',
    }));

  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    console.log('Handle submit pressed');

    setErrors({});

    let isValid = true;

    if (!formData.job_id) {
      setErrors(prevErrors => ({
        ...prevErrors,
        editJobError: 'Job not found'
      }));
      isValid = false;

      return;
    }

    if (!formData.created_by) {
      setErrors(prevErrors => ({
        ...prevErrors,
        editJobError: 'Please sign in to continue'
      }));
      isValid = false;
      // gContext.toggleSignInModal();

      return;
    }

    if (!formData.title) {
      setErrors(prevErrors => ({
        ...prevErrors,
        title: 'Please enter a job title'
      }));
      isValid = false;
    }

    if (!formData.description) {
      setErrors(prevErrors => ({
        ...prevErrors,
        description: 'Please enter job description'
      }));
      isValid = false;
    }

    if (!formData.job_category) {
      setErrors(prevErrors => ({
        ...prevErrors,
        job_category: 'Please select a job category'
      }));
      isValid = false;
    }

    if (!formData.qualification) {
      setErrors(prevErrors => ({
        ...prevErrors,
        qualification: 'Please select a qualification'
      }));
      isValid = false;
    }

    if (!formData.job_type) {
      setErrors(prevErrors => ({
        ...prevErrors,
        job_type: 'Please select a job type'
      }));
      isValid = false;
    }

    if (!formData.location) {
      setErrors(prevErrors => ({
        ...prevErrors,
        location: 'Please select a job location'
      }));
      isValid = false;
    }

    if (!formData.institution) {
      setErrors(prevErrors => ({
        ...prevErrors,
        institution: 'Please select an institution'
      }));
      isValid = false;
    }

    if (!formData.state) {
      setErrors(prevErrors => ({
        ...prevErrors,
        state: 'Please select a state'
      }));
      isValid = false;
    }

    if (!isValid) {
      return
    }

    try {

      // Make the POST request with formData
      const response = await axios.post(`${network.serverip}/api/edit-job-web`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });

      if (response.data.success && isMountedRef.current) {
        console.log('Job updated successfully');

        // Scroll to the top of the screen
        // window.scrollTo({
        //   top: 0,
        //   behavior: 'smooth'
        // });

        // handleSuccessMessage(response.data.message);
        // handleClear();

        handleGoBack()

      } else {
        console.error('Failed to update job');
      }

    } catch (error) {
      console.error('Error updating job:', error);

      if (error.response) {

        if (error.response.data.error) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...error.response.data.error,
          }));
        }

        if (error.response.data.message) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            editJobError: error.response.data.message,
          }));
        }

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          editJobError: 'Something went wrong, please try again',
        }));
      }
    }
  };



  const styles = DashboardJobEditStyling();

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        {/* <SidebarDashboard /> */}
        <div className="dashboard-main-container mt-25 mt-lg-31">
          <div className="container">

            {/* below is given for spacing */}
            <div className="d-block d-sm-none" style={{ height: '150px' }}></div>
            <div className="d-none d-sm-block" style={{ height: '90px' }}></div>

            <h3 className="text-green">Edit Job</h3>
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <p className="font-size-4 text-success min-height-40">
                    {successMessage || ''}
                  </p>
                </div>
                <form onSubmit={handleSubmit}>

                  <div className="form-group">
                    <label
                      htmlFor="image"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Image:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      name="image"
                      onChange={handleImageChange}
                      id="image"
                      ref={imageInputRef}
                      onFocus={() => setIsFocused('image')}
                      onBlur={() => setIsFocused('')}
                      style={isFocused === 'image' ? styles.focussdedInput : {}}
                    />
                    {errors?.image ? <p className="font-size-3 text-danger">
                      {errors.image}
                    </p> : null}
                    {imageUrl && (
                      <div className="d-flex justify-content-start mt-7">
                        <div style={styles.imageContainer}>
                          <img
                            src={imageUrl}
                            alt=""
                            className="img-fluid"
                            style={styles.imageStyle}
                          />
                          <button
                            type="button"
                            onClick={handleImageDelete}
                            style={styles.imageDeleteButton}
                          >
                            <i className="fas fa-times"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="title"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Title:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Job title"
                      id="title"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                      onFocus={() => setIsFocused('title')}
                      onBlur={() => setIsFocused('')}
                      style={isFocused === 'title' ? styles.focussdedInput : {}}
                    />
                    {errors?.title ? <p className="font-size-3 text-danger">
                      {errors.title}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="description"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Description:
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Job description"
                      id="description"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      rows="4"
                      onFocus={() => setIsFocused('description')}
                      onBlur={() => setIsFocused('')}
                      style={isFocused === 'description' ? styles.focussdedInput : {}}
                    />
                    {errors?.description ? <p className="font-size-3 text-danger">
                      {errors.description}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="job_category"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Select job category:
                    </label>
                    <select
                      id="job_category"
                      name="job_category"
                      className="form-control"
                      value={formData.job_category}
                      onChange={handleChange}
                      onFocus={() => setIsFocused('job_category')}
                      onBlur={() => setIsFocused('')}
                      style={isFocused === 'job_category' ? styles.focussdedInput : {}}
                    >
                      <option value="">Select a job category</option>
                      {jobCategories.map(jobCategory => (
                        <option key={jobCategory.id} value={jobCategory.id}>
                          {jobCategory.title}
                        </option>
                      ))}
                    </select>
                    {errors?.job_category ? <p className="font-size-3 text-danger">
                      {errors.job_category}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="job_type"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Select job type:
                    </label>
                    <select
                      id="job_type"
                      name="job_type"
                      className="form-control"
                      value={formData.job_type}
                      onChange={handleChange}
                      onFocus={() => setIsFocused('job_type')}
                      onBlur={() => setIsFocused('')}
                      style={isFocused === 'job_type' ? styles.focussdedInput : {}}
                    >
                      <option value="">Select a job type</option>
                      {jobTypes.map(jType => (
                        <option key={jType.id} value={jType.id}>
                          {jType.title}
                        </option>
                      ))}
                    </select>
                    {errors?.job_type ? <p className="font-size-3 text-danger">
                      {errors.job_type}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="qualification"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Select qualification:
                    </label>
                    <select
                      id="qualification"
                      name="qualification"
                      className="form-control"
                      value={formData.qualification}
                      onChange={handleChange}
                      onFocus={() => setIsFocused('qualification')}
                      onBlur={() => setIsFocused('')}
                      style={isFocused === 'qualification' ? styles.focussdedInput : {}}
                    >
                      <option value="">Select a qualification</option>
                      {qualifications.map(qual => (
                        <option key={qual.id} value={qual.id}>
                          {qual.title}
                        </option>
                      ))}
                    </select>
                    {errors?.qualification ? <p className="font-size-3 text-danger">
                      {errors.qualification}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="location"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Select location:
                    </label>
                    <select
                      id="location"
                      name="location"
                      className="form-control"
                      value={formData.location}
                      onChange={handleChange}
                      onFocus={() => setIsFocused('location')}
                      onBlur={() => setIsFocused('')}
                      style={isFocused === 'location' ? styles.focussdedInput : {}}
                    >
                      <option value="">Select a location</option>
                      {locations.map(loc => (
                        <option key={loc.id} value={loc.id}>
                          {loc.title}
                        </option>
                      ))}
                    </select>
                    {errors?.location ? <p className="font-size-3 text-danger">
                      {errors.location}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="state"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Select state:
                    </label>
                    <select
                      id="state"
                      name="state"
                      className="form-control"
                      value={formData.state}
                      onChange={handleChange}
                      onFocus={() => setIsFocused('state')}
                      onBlur={() => setIsFocused('')}
                      style={isFocused === 'state' ? styles.focussdedInput : {}}
                    >
                      <option value="">Select a state</option>
                      {states.map(sta => (
                        <option key={sta.id} value={sta.id}>
                          {sta.title}
                        </option>
                      ))}
                    </select>
                    {errors?.state ? <p className="font-size-3 text-danger">
                      {errors.state}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="skills"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Skills:
                    </label>
                    <Select
                      isMulti
                      name="skills"
                      options={allSkills.map(skill => ({ value: skill.id, label: skill.title }))} // Map allSkills to the required format
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleSkillsChange}
                      value={allSkills
                        .filter(skill => formData.skills.includes(skill.id)) // Filter to find selected skills based on formData.skills
                        .map(skill => ({ value: skill.id, label: skill.title }))} // Map to the required format for MultiSelect
                      placeholder="Select skills"
                      onFocus={() => setIsFocused('skills')}
                      onBlur={() => setIsFocused('')}
                      style={isFocused === 'skills' ? styles.focussdedInput : {}}
                    />
                    {errors?.skills ? <p className="font-size-3 text-danger">
                      {errors.skills}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="experience"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Experience:
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Experience"
                      id="experience"
                      name="experience"
                      value={formData.experience}
                      onChange={handleChange}
                      rows="2"
                      onFocus={() => setIsFocused('experience')}
                      onBlur={() => setIsFocused('')}
                      style={isFocused === 'experience' ? styles.focussdedInput : {}}
                    />
                    {errors?.experience ? <p className="font-size-3 text-danger">
                      {errors.experience}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="experience_in_months"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Experience (Years & Months):
                    </label>
                    <div className="d-flex">
                      <select
                        className="form-control mr-1"
                        name="experienceYear"
                        value={experienceYear}
                        onChange={onChangeYear}
                        id="experienceYear"
                        onFocus={() => setIsFocused('experienceYear')}
                        onBlur={() => setIsFocused('')}
                        style={isFocused === 'experienceYear' ? styles.focussdedInput : {}}
                      >
                        <option value="">Select Years</option>
                        {Array.from({ length: 31 }, (_, i) => (
                          <option key={i} value={i}>{i} Year(s)</option>
                        ))}
                      </select>
                      <select
                        className="form-control ml-1"
                        name="experienceMonth"
                        value={experienceMonth}
                        onChange={onChangeMonth}
                        id="experienceMonth"
                        onFocus={() => setIsFocused('experienceMonth')}
                        onBlur={() => setIsFocused('')}
                        style={isFocused === 'experienceMonth' ? styles.focussdedInput : {}}
                      >
                        <option value="">Select Months</option>
                        {Array.from({ length: 12 }, (_, i) => (
                          <option key={i} value={i}>{i} Month(s)</option>
                        ))}
                      </select>
                    </div>
                    {errors?.experience_in_months ? <p className="font-size-3 text-danger">
                      {errors.experience_in_months}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <label
                      htmlFor="institution"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Select hospital:
                    </label>
                    <select
                      id="institution"
                      name="institution"
                      className="form-control"
                      value={formData.institution}
                      onChange={handleChange}
                      onFocus={() => setIsFocused('institution')}
                      onBlur={() => setIsFocused('')}
                      style={isFocused === 'institution' ? styles.focussdedInput : {}}
                    >
                      <option value="">Select a hospital</option>
                      {institutions.map(inst => (
                        <option key={inst.id} value={inst.id}>
                          {inst.title}
                        </option>
                      ))}
                    </select>
                    {errors?.institution ? <p className="font-size-3 text-danger">
                      {errors.institution}
                    </p> : null}
                  </div>

                  <div className="form-group">
                    <div className="form-check-inline">
                      <label
                        htmlFor="featured_job"
                        className="col-form-label font-size-4 text-black-2 font-weight-semibold line-height-reset mr-5"
                      >
                        Set as featured job:
                      </label>
                      <input
                        type="checkbox"
                        id="featured_job"
                        name="featured_job"
                        checked={formData.featured_job === 1}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            featured_job: e.target.checked ? 1 : 0,
                          })
                        }
                      />
                    </div>
                    {errors?.featured_job ? (
                      <p className="font-size-3 text-danger">{errors.featured_job}</p>
                    ) : null}
                  </div>

                  <div className="form-group d-flex justify-content-end mt-3">
                    <button type="button"
                      onClick={handleGoBack}
                      className="btn btn-outline-primary mr-2">
                      Cancel
                    </button>
                    <button type="submit"
                      className="btn btn-primary">
                      Edit job
                    </button>
                  </div>

                  <div className="text-center">
                    {errors?.editJobError ? (
                      <p className="font-size-3 text-danger">{errors.editJobError}</p>
                    ) : null}
                  </div>

                </form>
              </div>
            </div>

            {/* below is given for spacing */}
            <div style={{ height: '50px' }}></div>

          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default DashboardJobEdit;

function DashboardJobEditStyling() {
  const styles = {

    imageContainer: {
      position: 'relative',
      display: 'inline-block'
    },

    imageStyle: {
      maxHeight: '200px',
      width: 'auto'
    },

    imageDeleteButton: {
      position: 'absolute',
      top: '-10px',
      right: '-10px',
      background: 'white',
      color: 'gray',
      borderRadius: '50%',
      border: 'none',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    focussdedInput: {
      borderColor: '#3498db',
      color: '#333',
      outline: 'none',
      borderWidth: '2px',
      boxShadow: '0 0 5px rgba(52, 152, 219, 0.5)',
    }

  };

  return styles;
}

